import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/templates/pageTemplate.js";
import Note from "../../components/text-decorations/note";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`This article will help you set up Gitlab pages from a specific folder in your repository. Let's assume that your documentation is living inside your project repository and built using `}<a parentName="p" {...{
        "href": "https://www.sphinx-doc.org/"
      }}>{`Sphinx`}</a>{`.`}</p>
    <p>{`To deploy your documentation site with Pages, you need to run a CI job to build the site. Gitlab will take care of the rest. You don't need to worry about domain names or Gitlab instance configurations if you do not use a dedicated Gitlab instance.`}</p>
    <p>{`When your documentation is deployed, the URL will look similar to this `}<inlineCode parentName="p">{`https://<username or organisation>.gitlab.io/<repo name>`}</inlineCode></p>
    <h2 {...{
      "id": "getting-gitlab-ci-setup",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#getting-gitlab-ci-setup",
        "aria-label": "getting gitlab ci setup permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Getting Gitlab CI setup`}</h2>
    <p>{`Pages will be deployed once the CI job finishes running. The good thing is that if you already have a CI flow setup, all you need to do is add another job to deploy the pages. If you don't have one, you might be interested in `}<a parentName="p" {...{
        "href": "https://docs.gitlab.com/ee/user/project/pages/getting_started/pages_ci_cd_template.html"
      }}>{`reading about pages ci template`}</a>{`.`}</p>
    <p>{`Create a `}<inlineCode parentName="p">{`.gitlab-ci.yaml`}</inlineCode>{` file at the root of your project. We will create two jobs to test if the documentation will build on every merge request and deploy your documentation from a branch called `}<inlineCode parentName="p">{`production`}</inlineCode>{`.`}</p>
    <p>{`Since the first job will run on every deployment, you will know if something is broken before deploying a new version of your documentation.`}</p>
    <h3 {...{
      "id": "creating-the-first-job---testing-the-build",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#creating-the-first-job---testing-the-build",
        "aria-label": "creating the first job   testing the build permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Creating the first job - Testing the build`}</h3>
    <p>{`We are now ready to start working on our CI template. Let's assume that your documentation lives in a folder inside the root directory of your project and that you have a `}<inlineCode parentName="p">{`requirements.txt`}</inlineCode>{` file with some dependencies, for example:`}</p>
    <pre><code parentName="pre" {...{}}>{`sphinx
sphinx-copybutton
sphinxawesome-theme
`}</code></pre>
    <p>{`Let's create a `}<inlineCode parentName="p">{`.gitlab-ci.yaml`}</inlineCode>{` file in the root directory of your project to install the requirements and run the build command to see if everything will build correctly.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-yaml"
      }}>{`image: python:3.8-alpine

stages:
  - checks
  - deploy

test-docs:
  stage: checks
  script:
    - pip install -r requirements.txt
    - cd documentation
    - sphinx-build -b html . preview
  artifacts:
    expose_as: "docs-preview"
    paths:
      - preview/
`}</code></pre>

    <p>{`The script portion of the job will install the dependencies and then build your docs in a `}<inlineCode parentName="p">{`preview`}</inlineCode>{` folder.`}</p>
    <p>{`The artifacts portion will allow Gitlab to expose the built docs to you so you can see a preview of your documentation. Gitlab will add a collapsed section to the CI pipeline status once the CI has finished so you can see the built.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "500px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/71a5c8ff6d43b422bc3b968e3d68107f/5f7f3/gitlab-artifact.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "12%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAACCAIAAADXZGvcAAAACXBIWXMAAA7DAAAOwwHHb6hkAAAAQElEQVQI143BwRGAMAgEwPTfa0TgjiM8HAvw4+5il7llpLtTUz2ZURrqRISk5wMNFOfMInHduQ3bQNYfIECQfAGKT3Nzu5VxQwAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Gitlab pipeline",
            "title": "Gitlab pipeline",
            "src": "/static/71a5c8ff6d43b422bc3b968e3d68107f/0eb09/gitlab-artifact.png",
            "srcSet": ["/static/71a5c8ff6d43b422bc3b968e3d68107f/c59a0/gitlab-artifact.png 125w", "/static/71a5c8ff6d43b422bc3b968e3d68107f/86700/gitlab-artifact.png 250w", "/static/71a5c8ff6d43b422bc3b968e3d68107f/0eb09/gitlab-artifact.png 500w", "/static/71a5c8ff6d43b422bc3b968e3d68107f/e9985/gitlab-artifact.png 750w", "/static/71a5c8ff6d43b422bc3b968e3d68107f/5f7f3/gitlab-artifact.png 971w"],
            "sizes": "(max-width: 500px) 100vw, 500px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <Note mdxType="Note">
      <p>
  If you struggle with building the pages, I recommend that you check the{" "}
  <a href="https://gitlab.com/pages" target="_blank" rel="noopener noreferrer">
    Gitlab examples websites
  </a>{" "}
  to see how to get your chosen static site deployed Gitlab.
      </p>
    </Note>
    <h3 {...{
      "id": "creating-the-second-job---deploying-to-pages",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#creating-the-second-job---deploying-to-pages",
        "aria-label": "creating the second job   deploying to pages permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Creating the second job - Deploying to pages`}</h3>
    <p>{`The second job will be similar to our `}<inlineCode parentName="p">{`test-docs`}</inlineCode>{` one, with some minor changes. We will change the stage to deploy, we will add a rule to run the job only when it's added to the `}<inlineCode parentName="p">{`production`}</inlineCode>{` branch, and we will call this job `}<inlineCode parentName="p">{`pages`}</inlineCode>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-yaml"
      }}>{`pages:
  stage: deploy
  rules:
    - if: $CI_MERGE_REQUEST_SOURCE_BRANCH_NAME =~ /^production/
  script:
    - pip install -r requirements.txt
    - cd documentation
    - sphinx-build -b html . public
  artifacts:
    expose_as: "docs-deploy-preview"
    paths:
      - public/
`}</code></pre>
    <p>{`Let's take a brief moment to talk about how Gitlab expects you to pass things, so it knows where to find your files. The job deployed to Gitlab pages has to have the name `}<inlineCode parentName="p">{`pages`}</inlineCode>{`. But another thing that you need to do is that the files are located in the `}<inlineCode parentName="p">{`public`}</inlineCode>{` folder.`}</p>
    <p>{`Depending on your project, you might not want to run the deploy stage every time you commit to the production branch. You can change how the job starts by adding another rule `}<inlineCode parentName="p">{`when: manual`}</inlineCode>{`. This means you will have to click the start icon to start the job manually.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-yaml"
      }}>{`pages:
  stage: deploy
  rules:
    - if: $CI_MERGE_REQUEST_SOURCE_BRANCH_NAME =~ /^production/
      when: manual
  script:
    - pip install -r requirements.txt
    - cd documentation
    - sphinx-build -b html . public
  artifacts:
    expose_as: "docs-deploy-preview"
    paths:
      - public/
`}</code></pre>
    <h2 {...{
      "id": "tie-it-all-together",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#tie-it-all-together",
        "aria-label": "tie it all together permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Tie it all together`}</h2>
    <p>{`That's all there is to it. I hope this article was helpful for you, and hopefully, I have saved you some time. When I was trying to implement this, I found the Gitlab documentation confusing. It seems to focus more on setting up pages if you are not using a shared Gitlab instance and need to set up everything yourself.`}</p>
    <p>{`Our finished `}<inlineCode parentName="p">{`.gitlab-ci.yaml`}</inlineCode>{` file looks like this`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-yaml"
      }}>{`image: python:3.8-alpine

stages:
  - checks
  - deploy

test-docs:
  stage: checks
  script:
    - pip install -r requirements.txt
    - cd documentation
    - sphinx-build -b html . preview
  artifacts:
    expose_as: "docs-preview"
    paths:
      - preview/
pages:
  stage: deploy
  rules:
    - if: $CI_MERGE_REQUEST_SOURCE_BRANCH_NAME =~ /^production/
      when: manual
  script:
    - pip install -r requirements.txt
    - cd documentation
    - sphinx-build -b html . public
  artifacts:
    expose_as: "docs-deploy-preview"
    paths:
      - public/
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      